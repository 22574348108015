@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.Home_font__19u0V {
    font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
}
.project-container {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.carousel-container {
	position: relative;
	max-width: 800px;
	margin: auto;
}

.button-back {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.button-next {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.bottom-title {
	bottom: 0;
	width: 100%;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.1);
	-webkit-backdrop-filter: blur(4px);
	        backdrop-filter: blur(4px);
	padding: 1em;
}

.image-container {
	position: relative;
	border-radius: 1em;
}

.carousel-center {
	margin: auto auto auto auto;
}

* {
  font-family: 'Open Sans';
}
